<template>
    <div class="allmsgclass">
        <navigation msg="事业合伙人协议"></navigation>
        <pre><h1>萝卜猎手居间服务合作协议</h1>
本协议由以下双方于    年  月  日在  上海市 签订：
甲  方： 上海猎后征信服务有限公司
联系地址：上海市凯旋南路923号悦办公二楼	
联 系 人： 陈娴
联系电话：18601557985
联系邮箱：
 
乙  方： 
联系地址：
联 系 人：
联系电话：
联系邮箱：

鉴于：
    甲方有意授权乙方在乙方业务所涉区域内协助甲方寻找可与甲方建立服务关系的企业级用户（以下简称“企业用户”）或推广渠道帮助甲方实现产品销售，乙方亦同意依照本协议之约定为甲方推荐可与甲方建立服务关系的企业用户或推广渠道。
    甲、乙双方根据中华人民共和国有关法律、法规的规定，本着平等互利的原则，经友好协商，就乙方向甲方提供居间服务合作事宜达成以下协议：

第一条 合作内容
1、甲方 “萝卜猎手”(www.luobolieshou.com)是一个大数据用工服务平台，包括但不限于背景调查，员工档案、职业评价等服务。甲乙双方承认乙方为甲方在人力资源服务领域内的战略合作伙伴，乙方将凭借自有的客户资源等优势，积极向意向客户推荐甲方的系列产品和服务。
2、双方同意，乙方以居间商名义向甲方推荐存在业务需求的企业用户或其他推广渠道，由乙方推荐的企业用户或乙方推荐渠道再推荐的企业用户完成产品或服务的购买，甲方根据本协议向乙方支付居间费用。
3、乙方充分理解甲方的合作意向及内容，同意为甲方推荐符合前述条件的企业用户及推广渠道。
4、乙方理解并确认，甲方有权授权其他第三方为甲方推荐合适的企业用户，乙方并非独家居间代理。
5、乙方承诺每月/季度至少应向甲方推荐         个有效企业用户，以甲方与乙方推荐的企业用户签订最终协议为准。如乙方未能达到上述标准，甲方有权提前终止本协议，并有权拒绝支付后续费用。
6、乙方需在甲方平台完成充值  1000元   2000元作为合作金，双方结束合作的30个工作日内，甲方将乙方账户余额（不超过首次充值1000/2000元）退还至乙方银行账户，同时在萝卜猎手平台的乙方账户扣除相应金额。

第二条 费用支付
1、甲方向乙方支付的居间服务费分两类：A类销售奖金，B类渠道奖金。根据乙方提供居间服务的实际情况，A、B两类奖金可同时获得。
2、A类销售奖金指乙方推荐的企业用户与甲方完成协议签署并在甲方平台完成足额充值，甲方根据企业客户充值金额向乙方支付相应的销售奖金（即乙方销售奖金=企业客户充值金额*奖金比例%）。自签约日期起12个月内，乙方推荐所有企业客户的充值金额可累加计算，当月奖金比例取值以截至当月月底所有客户合计累计充值金额所在区间计算，不同累计金额对应阶梯状奖金比例，佣金比例以实际商议为准。超出约定居间合作期限，如甲方仍与乙方所推荐的企业客户建立业务关系的，甲乙双方协商续约或重新签订协议
3、B类渠道奖金指1）乙方介绍意向客户，但是不负责跟进和成交，由甲方跟进和成交；或2）乙方介绍下级渠道，下级渠道和甲方签署居间协议，帮助甲方完成企业客户的协议签约及产品销售，乙方可获得相应的B类渠道奖金（即乙方渠道奖金=介绍意向客户/下级渠道对应的累计充值金额*奖金比例%），1）和2）可同时获得。乙方按下表计算对应奖金比例：
    <table>
        <tr>
          <td>累计金额（单位：元 人民币）</td>
          <td>奖金比例（%）</td>
        </tr>
        <tr>
          <td>不限金额</td>
          <td>10</td>
        </tr>
    </table> 
4、上述居间服务费用为乙方履行本协议项下义务甲方所需支付的所有费用（含开具增值税专用发票的税费），如乙方未成功推荐企业用户或推荐的企业用户违约的，甲方无需向乙方支付任何费用。乙方因履行本协议项下义务所发生的差旅费、活动费由其自行承担。
5、甲方按月统计乙方成功居间代理充值金额（以甲方实际到账为准），并于次月15日开始结算对应月份居间服务费，甲方于每月15日前将居间服务费及结算单发予乙方确认，乙方应于5个工作日确认并根据甲方要求开具增值税专用发票，甲方收到发票后5个工作日内付清上月的居间服务费。若乙方是个人居间商，则不需要根据甲方要求开增值税专用发票，甲方支付给乙方的服务费每次需扣除6.72%服务费。
6、乙方指定的银行收款账号为
开户名称：                     
开户银行：                       
银行账号：   

第三条 合作期限
1、合作期限 1 年，自  2021  年     月    日起至 2022  年      月     日止。
2、合作期限届满前 30 天，任何一方均可以向另一方书面提出续签合同，合同经双方协商后续签。

第四条 保密条款
1、本台同所指的“保密信息＂，包括但不限于双方在履行本合同的过程中所接触、获悉的包括本合同在内的任何商业秘密和具有保密价值的对方信息（含企业信息、背调个人信息等），无论该保密信息以何种形式载于何种载体都要严格保密。
2、本合同所指的“披露方”是指披露保密信息的一方，“保密方”是指接受保密信息的一方。
3、未经披露方事先书面同意，保密方不得向任何第三方披露保密信息及本合同的存在。
4、除本合同规定工作所需外，未经披露方事先书面同意，保密方不得擅自用做其他商业用途。
5、保密条款永久有效，不因本合同的终止而终止。
6、如果甲乙任何一方的行为违反保密约定 ，各自依法承担因其自身的行为所造成的切法律责任，以及赔偿另一方相应经济损失。

第五条 违约责任
1、本合同生效后，甲乙双方均应当遵守本合同的约定，履行本合同项下的义务， 任何一方不得违反本合同的约定。若因任何一方的违约行为给对方造成损失的，违约方应当承担相应的赔偿责任。
2、乙方应确保推荐的企业用户系独立的第三方，乙方保证其与推荐的企业用户不存在利益输送的情形，其提供居间服务过程中未进行商业贿赂、不正当竞争或其他违法违规事宜，未实施损害甲方或其推荐企业用户利益的行为，如乙方有上述行为，甲方有权取消乙方合作资格、不予支付佣金，并追究乙方的法律责任。
3、为保持品牌输出的统一性，乙方需要按照甲方指定的标准话术进行对方输出，不得出现对外擅自承诺用户并收取款项、代查等不良现象，一经发现，甲方有权取消乙方合作资格、不予支付佣金，并追究乙方的法律责任。
4、在本合同履行过程中，发生下列情形之一的，本合同自动终止。
1) 因不可抗力事件， 致使本合同无法履行的；
2) 因一方违反国家法律、法规或违背本合同的行为，另一方有权单方面终止本合同；
3) 本合同期限届满的；
4) 双方任何一方书面提出终止合同后 1 个月， 合同终止， 终止之日起除已发生的佣金核算， 双方无须承担其他违约责任（保密条款除外）。

第六条 不可抗力
1、一方因不可抗力不能履行协议或不能完全履行协议的，根据不可抗力的影响允许延期履行、部分履行或者不履行协议，遭受不可抗力的一方可以部分或全部免除责任。未受不可抗力影响的部分应继续履行。
2、任何一方由于不可抗力的原因不能履行协议时，应立即以书面形式通知对方不能履行或不能完全履行的原因，并在不可抗力发生后十五日内提供有关政府部门、公证机关或商会出具的证明以证实不可抗力的存在。本协议中，不可抗力指不能预见、不能避免且不能克服的客观情况。

第七条 争议解决
    本协议的订立、执行和解释及争议的解决均应适用中国（为本协议方便表述之目的，中国不含香港、澳门、台湾地区）法律。凡因本协议引起的或与本协议有关的任何争议，双方应友好协商解决。如不能协商解决，任一方有权向协议签订地有管辖权的人民法院提起诉讼。

第八条 其他
1、如乙方在本协议期限内更换地址或联系方式的，应及时通知甲方，因乙方未及时告知导致其无法收到相关文件、信息的，甲方无需承担任何责任。
2、本协议自双方签字或盖章之日起生效，本协议一式贰份，甲乙双方各执壹份，具有同等法律效力。
3、双方同意，在本协议签署后，就本协议未尽事宜，双方可进行进一步的协商，并达成补充协议。该等补充协议构成本协议不可分割的组成部分，且与本协议具有同等法律效力。
        </pre>
    </div>
</template>
<script>
import navigation from "../../components/navigation/index.vue"
export default {
    components:{
        navigation
    }
}
</script>
<style scoped>
.allmsgclass >>> .van-nav-bar .van-icon{
    color: #000000;
}
h1{
    font-size: .5rem;
    text-align: center;
    margin: 0;
}
.allmsgclass{
    background-color: #ffffff;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
  margin-bottom: 0;
}
table{
  border-collapse: collapse;
}
tr{
  border: 1px solid #000000;
}
td{
  border: 1px solid #000000;
  text-align: center;
}
</style>